// fundamentals
import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet-async';

// components
import Footer from '../../Components/footer';
import Arasuri from '../../Components/arasuri';
import './aboutUs.scss';

// images
import kaushikModi from '../../Assets/Images/About_us/Kaushik Modi.png';
import MihirPatel from '../../Assets/Images/About_us/Mihir Patel Ambaji Temple.jpeg';
import BuildingIcon from '../../Assets/Images/About_us/BuildingIcon.svg';
import GraduationCap from '../../Assets/Images/About_us/GraduationCap.svg';
import MedicalClinicIcon from '../../Assets/Images/About_us/MedicalClinicIcon.svg';
import ShoppingBagIcon from '../../Assets/Images/About_us/ShoppingBagIcon.svg';
import LazyLoader from '../../Components/lazyLoader';

const AboutUs = () => {
  const [isLoading, setLoading] = useState(true);
  useEffect(() => {
    setTimeout(() => {
      setLoading(false);
    }, [5000]);
  });
  const [activeIndex, setActiveIndex] = useState(0);
  const [showTrust, setShowTrust] = useState(true);
  const [showEducation, setShowEducation] = useState(false);
  const [showMedical, setShowMedical] = useState(false);
  const [showSari, setShowSari] = useState(false);

  //   for trust
  const handleTrustClick = () => {
    setActiveIndex(0);
    setShowTrust(true);
    setShowEducation(false);
    setShowMedical(false);
    setShowSari(false);
  };

  //   for education
  const handleEducationClick = () => {
    setActiveIndex(1);
    setShowTrust(false);
    setShowEducation(true);
    setShowMedical(false);
    setShowSari(false);
  };

  //   for media
  const handleMedicalClick = () => {
    setActiveIndex(2);
    setShowTrust(false);
    setShowEducation(false);
    setShowMedical(true);
    setShowSari(false);
  };

  //   for sari
  const handlesariClick = () => {
    setActiveIndex(3);
    setShowTrust(false);
    setShowEducation(false);
    setShowMedical(false);
    setShowSari(true);
  };
  return (
    <>
      <Helmet>
        <title>
          Shri Ambaji Mata Devasthan Trust | Ambaji Temple | Banaskantha Gujarat
        </title>
        <meta
          name="description"
          content="Managed by Shri Ambaji Mata Devasthan Trust, Ambaji Temple is one of the 51 Shakti
Peethas where the heart of Goddess Sati fell. Experience live darshan, view temple and aarti
timing, donate online, contribute to the Suvarna Shikhar Gold, book Pavdi Puja, Dhaja, and
Yagna through our website. Join us in worship and devotion from anywhere in the world."
        />
        <meta
          name="keywords"
          content="Ambaji Temple, Ambaji Temple queries, Ambaji Temple information, Temple contact details,
Banaskantha temple contact, Religious assistance India, Ambaji Temple, Ambaji Mataji,
Ambaji Gujarat, Ambaji Darshan, Ambaji Temple Timing, Ambaji Temple Gabbar"
        />
        <meta
          name="author"
          content="Ambaji Temple and Gabbar Banaskantha Gujarat | BinTech Services Ahmedabad"
        />
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
      </Helmet>
      {!isLoading ? (
        <div className="main-wrapper">
          {/* banner  */}
          <section
            className="header-banner aboutUs-banner"
            // style={{ backgroundImage: 'url("aboutimg.jpg")' }}
          >
            <div className="container">
              <h1>અમારા વિશે</h1>
            </div>
          </section>
          {/* ---------  */}

          {/* details  */}
          <section className="about-page">
            <div className="container">
              <div className="about-main">
                <div className="about-nav">
                  <ul>
                    <li className={activeIndex === 0 ? 'active' : ''}>
                      <a href="#" onClick={handleTrustClick}>
                        <img
                          src={BuildingIcon}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                        ટ્રસ્ટ
                      </a>
                    </li>
                    <li className={activeIndex === 1 ? 'active' : ''}>
                      <a href="#" onClick={handleEducationClick}>
                        <img
                          src={GraduationCap}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                        શિક્ષણ
                      </a>
                    </li>
                    <li className={activeIndex === 2 ? 'active' : ''}>
                      <a href="#" onClick={handleMedicalClick}>
                        <img
                          src={MedicalClinicIcon}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                        મેડિકલ
                      </a>
                    </li>
                    <li className={activeIndex === 3 ? 'active' : ''}>
                      <a href="#" onClick={handlesariClick}>
                        <img
                          src={ShoppingBagIcon}
                          alt="Ambaji Temple Banaskatha Gujarat"
                        ></img>
                        સાડી વેચાણ કેન્દ્ર
                      </a>
                    </li>
                  </ul>
                </div>

                {/* for education  */}

                {showEducation && (
                  <div className="about-tabs">
                    <h2>
                      ટ્રસ્ટ પ્રવૃત્તિ : <span>શૈક્ષણિક</span>
                    </h2>
                    <h3>
                      શ્રી દ્વારા બનાવવામાં આવેલ ન્યુ કોલેજ કેમ્પસ; આરાસુરી
                      અંબાજી માતા દેવસ્થાન ટ્રસ્ટ
                    </h3>
                    <h3>શ્રી અંબાજી આર્ટસ કોલેજ</h3>
                    <p>
                      અંબાજી અને તેની આસપાસના વિસ્તારના યુવાનોને આધુનિક શિક્ષણનો
                      લાભ મળે અને આવા શિક્ષણ દ્વારા આ આદિવાસી વિસ્તારનો સર્વાંગી
                      વિકાસ થઈ શકે તેવા આશયથી શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન
                      ટ્રસ્ટ દ્વારા 1991માં શ્રી અંબિકા આર્ટસ કોલેજ શરૂ કરવામાં
                      આવી છે. હાલમાં ગ્રેજ્યુએશનમાં 841 અને પોસ્ટ ગ્રેજ્યુએશન
                      કોર્સમાં 107 થી વધુ વિદ્યાર્થીઓ અહીં અભ્યાસ કરી રહ્યા છે
                      અને શ્રી અંબાજી આર્ટસ કોલાજમાંથી તેમનું શિક્ષણ લે છે. આ
                      ઉપરાંત હવે આ કોલેજમાં હિન્દીમાં એક વિશેષ અનુસ્નાતક અધ્યાપક
                      પણ દાખલ કરવામાં આવ્યો છે. આજકાલ આખું કેમ્પસ એક મોટા
                      શૈક્ષણિક સંકુલમાં નવી ઇમારતો, છાત્રાલય, પુસ્તકાલય, સ્ટાફ
                      ક્વાર્ટર્સ, ઓવરહેડ પાણીની ટાંકી, 10 એકર જમીન પર કમ્પાઉન્ડ
                      વોલથી ઘેરાયેલો બગીચો, લગભગ રૂ. 7 કરોડ અને તેથી વધુના જંગી
                      ખર્ચે નવીનીકરણ કરવામાં આવ્યું છે.
                    </p>
                    <h3>ન્યૂ એકેડેમી:</h3>
                    <p>
                      નવી એકેડમી તેના આયોજન મુજબ, નવું કોલેજ કેમ્પસ 3890.61 ચોરસ
                      મીટર વિસ્તારમાં ફેલાયેલું છે, જેમાં દરેક હોલમાં 80
                      વિદ્યાર્થીઓની ક્ષમતા સાથે 8 મોટા લેક્ચર હોલ અને દરેક
                      હોલમાં 50 વિદ્યાર્થીઓની બેસવાની ક્ષમતા ધરાવતા 8 નવા લેક્ચર
                      હોલ અને બે મોટા સેમિનાર હોલ છે, સ્ટાફ રૂમ, બોયઝ રૂમ,
                      ગર્લ્સ રૂમ, સ્પોર્ટ્સ રૂમ, એનસીસી અને એનએસએસ રૂમ,
                      પ્રિન્સિપાલની ઓફિસ અને અન્ય સ્ટાફ, તેમજ એક મોટી કેન્ટીન,
                      કુલ રૂ. 3,13,36,860/-એ અલગથી 1,74,20,349/- ના ખર્ચે
                      3149.11 ચોરસ મીટર વિસ્તારમાં બહુમાળી મોટી હોસ્ટેલ, જેમાં
                      120 વિદ્યાર્થીઓ માટે બે મોટા બ્લોક્સ છે, જેમાં 80 છોકરાઓ
                      માટે 40 રૂમ અને 40 છોકરીઓ માટે 20 રૂમ છે, જેમાં એક મનોરંજન
                      રૂમ પણ છે. , શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ
                      દ્વારા વિશાળ ડાઇનિંગ હોલ અને આધુનિક શૌચાલય બ્લોક્સ ડિઝાઇન
                      કરવામાં આવ્યા છે.
                    </p>
                    <h3>કોલેજ લાયબ્રેરી</h3>
                    <p>
                      શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટે પણ તાજેતરમાં
                      લગભગ 352.23 ચોરસ મીટર વિસ્તારમાં એક આધુનિક કોલેજ
                      પુસ્તકાલયની સ્થાપના કરી છે જેમાં લગભગ 8.37 X 16.44 ચોરસ
                      મીટરનો મોટો સ્ટોર રૂમ છે.
                    </p>
                    <p>
                      શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ ભારતીય સંસ્કૃતિ
                      અને સંસ્કૃત ભાષા અને વૈદિક સાહિત્યના વિકાસ, પ્રચાર અને
                      સંરક્ષણના હેતુ માટે શ્રી અંબિકા સંસ્કૃત મહાવિદ્યાલયનું પણ
                      સંચાલન કરે છે. 1970 થી. અહીં આ મહાવિદ્યાલયમાં, સંસ્કૃતનું
                      શિક્ષણ ખૂબ જ જૂની પરંપરાગત રીતે આપવામાં આવે છે, જેથી આપણા
                      હિંદુ શાસ્ત્રોમાં વર્ણવેલ છે.
                    </p>
                    <p>
                      સંસ્કૃત ભણવા માટે બહારના સ્થળોથી અહીં આવતા તમામ
                      વિદ્યાર્થીઓને કુલ મફત રહેવા-જમવાની સુવિધા પણ પૂરી પાડવામાં
                      આવે છે. સંસ્કૃત મહાવિદ્યાલયમાં એક ખૂબ જ સારી પુસ્તકાલય પણ
                      છે, જેમાં ભાગ્યે જ ઉપલબ્ધ પવિત્ર ધાર્મિક પુસ્તકો અને વેદ,
                      દર્શન શાસ્ત્ર, ધર્મશાસ્ત્ર, પુરાણ અને સંસ્કૃત સાહિત્યના
                      અન્ય પ્રાચીન અને આધુનિક પુસ્તકો પરના જૂના ગ્રંથોનો ખજાનો
                      છે. સમગ્ર ઉત્તર ગુજરાત ભારતમાં આ એક ખૂબ જ નોંધપાત્ર
                      શૈક્ષણિક સંસ્થા અને સંસ્કૃત એકેડેમી છે. શ્રી અંબિકા
                      સંસ્કૃત મહા વિદ્યાલયમાં વર્ષ 2000-2001 થી અત્યાર સુધીમાં
                      345 વિદ્યાર્થીઓ એક સર્વેક્ષણ મુજબ જોડાયા છે અને શ્રી
                      આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ સંસ્કૃતના પ્રચાર માટે
                      લાખો રૂપિયાના ખર્ચે આ સંસ્થાનો તમામ ખર્ચ ઉઠાવે છે.
                    </p>
                  </div>
                )}

                {/* for trust  */}

                {showTrust && (
                  <div className="about-tabs">
                    <h2> જય અંબે </h2>
                    <p>
                      શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ'' એ 1960 ના
                      વર્ષમાં સ્થપાયેલું જાહેર ટ્રસ્ટ છે. ટ્રસ્ટ એ એક સરકારી
                      સંસ્થા છે જે ચેરિટી કમિશનર સાથે પણ નોંધાયેલ છે. મંદિરનો
                      સંપૂર્ણ વહીવટ સરકારના નિયમો અને નિયમો હેઠળ સરકારી અધિકારીઓ
                      દ્વારા ચલાવવામાં આવે છે. વર્ષોથી, અંબાજી ટ્રસ્ટ અંબાજીમાં
                      આવતા તમામ શ્રદ્ધાળુઓને સેવાઓ આપનાર સૌથી અગ્રણી ધાર્મિક
                      ટ્રસ્ટ તરીકે ઉભરી આવ્યું છે. મંદિરની પ્રવૃત્તિઓ ઉપરાંત,
                      ટ્રસ્ટ શિક્ષણ, આરોગ્ય, સામાજિક સમાનતા, આપત્તિ
                      વ્યવસ્થાપનમાં મદદ વગેરે જેવી ઘણી સામાજિક અને પરોપકારી
                      પ્રવૃત્તિઓમાં પણ સક્રિયપણે શામેલ છે.
                    </p>
                  </div>
                )}

                {/* for medical  */}

                {showMedical && (
                  <div className="about-tabs">
                    <h2>
                      શ્રી અંબાજી ટ્રસ્ટ જનરલ હોસ્પિટલ (નવી આધુનિક કોટેજ
                      હોસ્પિટલની પ્રોજેક્ટ ડિઝાઇન)
                    </h2>
                    <p>
                      શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ સ્થાનિક રહેવાસીઓ
                      તેમજ સમગ્ર વિશ્વમાંથી આવતા માતા અંબાજીના તમામ ભક્તોને તમામ
                      પ્રકારની સુવિધાઓ આપવા માટે હંમેશા સક્રિય રહે છે. શ્રી
                      આરાસુરી અંબાજી માતા દેવસ્થાન ટ્રસ્ટ દ્વારા સુરક્ષાના મોટા
                      હિતમાં દર વર્ષે આશરે રૂ. 12 લાખના ખર્ચ સાથે, 45 પથારીવાળી
                      આધુનિક કુટીર હોસ્પિટલ, કેટલાક કોલીફાઇડ નિવાસી ડોકટરોની
                      સીધી દેખરેખ અને સેવાઓ હેઠળ ચલાવવામાં આવે છે અને તેનું
                      સંચાલન પણ કરવામાં આવે છે. અંબાજી અને આસપાસના આદિવાસી
                      વિસ્તારના લોકોનું આરોગ્ય આ કોટેજ હોસ્પિટલમાં તેની પોતાની
                      પેથોલોજીકલ લેબોરેટરી, એક્સ-રે ક્લિનિક, લેબર રૂમ, પુરુષ અને
                      સ્ત્રી વોર્ડ અને સ્ત્રીરોગવિજ્ઞાન તેમજ અન્ય સર્જિકલ વિભાગો
                      છે. કરાયેલા સર્વે મુજબ આ હોસ્પિટલમાં 150 - 200 થી વધુ
                      આઉટડોર દર્દીઓ અને 10 થી 15 જેટલા ઇન્ડોર દર્દીઓ દરરોજની
                      તબીબી સેવાઓ તદ્દન વિનામૂલ્યે મેળવે છે. આ ઉપરાંત આ હોસ્પિટલ
                      દ્વારા દર સોમવારે મધર એન્ડ ચાઇલ્ડ વેલફેર ક્લિનિકલ સેવાઓની
                      પણ વ્યવસ્થા કરવામાં આવે છે અને દર ગુરુવારે લેપ્રસી
                      ક્લિનિકલ સેવાઓ પૂરી પાડવામાં આવે છે. કોટેજ હોસ્પિટલની આ
                      તબીબી સુવિધા અંબાજી અને આસપાસના આદિવાસી વિસ્તારના લોકો
                      માટે વરદાનરૂપ સાબિત થઈ છે. SAAMDT કેટલીક વિશેષતાઓના
                      ડોકટરોની મુલાકાત લેવાની વ્યવસ્થા કરવાનો પણ સફળતાપૂર્વક
                      પ્રયાસ કરે છે.
                    </p>
                    <p>
                      અંબાજી શક્તિપતિ દાંતા તાલુકા આદિવાસી પટ્ટામાં આવેલું છે.
                      છેલ્લા ચાલીસ વર્ષથી શ્રી આરાસુરી અંબાજી માતા દેવસ્થાન
                      ટ્રસ્ટ, અંબાજી દ્વારા આ આદિવાસી વિસ્તારમાં 85 હજારથી વધુ
                      દર્દીઓને તબીબી સુવિધા આપવામાં આવી છે. હાલની કોટેજ હોસ્પિટલ
                      અંદાજે 45 થી 50 વર્ષ જૂની છે. સ્ટીલ કાટખૂણે છે અને છત
                      બરબાદ થઈ ગઈ છે. વરસાદમાં છતમાંથી લીકેજ થાય છે. આ કારણોસર
                      જૂની કોટેજ હોસ્પિટલને તોડીને દર્દીઓ માટે વધુ સારી સુવિધા
                      સાથે નવી હોસ્પિટલ બનાવવાની દરખાસ્ત છે. ગુજરાત અને
                      રાજસ્થાનના દર્દીઓ લગભગ 6000 ચોરસ કિલોમીટર દૂર દૂરથી કોટેજ
                      હોસ્પિટલમાં આવે છે. દર્દીઓની દિન-પ્રતિદિન વધતી જતી વસ્તી
                      અને જૂની હોસ્પિટલમાં પ્રવર્તતી પરિસ્થિતિઓને ધ્યાનમાં
                      રાખીને દર્દીઓની વધતી સંખ્યાના હિતમાં આધુનિક સાધનો સાથે નવી
                      હોસ્પિટલ માટે નવા સ્થળે સ્થળાંતર કરવાનું આયોજન કરવામાં
                      આવ્યું છે.
                    </p>
                    <p>
                      હવે એવી દરખાસ્ત કરવામાં આવી છે કે અંબાજીની નવી હોસ્પિટલમાં
                      200 બેડ હશે;12 સ્પેશિયલ રૂમ; 10 સેમી-એસી રૂમ; 6 ઓપીડી; 10
                      મુલાકાત લેતા ડોકટરોના રૂમ; કટોકટી એકમ; આઈસીયુ; CCU એકમો;
                      વિવિધ વોર્ડ જેવા કે ઓર્થોપેડિક, ઓપ્થેલ્મિક, ગાયનેક,
                      સેપ્ટિક, સર્જીકલ, બર્ન્સ, એન્ડોસ્કોપ, ટી.બી. વગેરે .
                      કાફેટેરિયા , ક્લોક રૂમ , આરામ ખંડ , શૌચાલય , મેડિકલ સ્ટોર
                      , લોન્ડ્રી , ઇલેક્ટ્રિક યાર્ડ , ડીજી સેટ , ગેસ સપ્લાય ,
                      ઓક્સિજન અને બ્લડ બેંકો . આ નવી હોસ્પિટલ બિલ્ડીંગ માટે
                      અંદાજે રૂ: 6.39 કરોડનો ખર્ચ થશે, ઉપર દર્શાવેલ નવા
                      પ્રોજેક્ટ પ્લાન મુજબ નીચેની જૂની હોસ્પિટલ બિલ્ડીંગને જગ્યા
                      એ આ નવું માળખું ઊભું કરવામાં આવશે.
                    </p>
                  </div>
                )}

                {/* for sari selling centre  */}

                {showSari && (
                  <div className="about-tabs">
                    <p>
                      માતાજીને અર્પણ કરવાની સાડી - સ્વીકાર કેન્દ્ર ખાતે
                      સ્વીકારવામાં આવે છે તથા માતાજીને ચઢાવેલ સાડી ૫૦%
                      ડિસ્કાઉન્ટ થી સાડી - વેચાણ કેન્દ્ર ખાતે ખરીદી શકાય છે.
                    </p>
                  </div>
                )}
              </div>
            </div>
          </section>
          {/* ---------  */}

          {/* leaders  */}
          <section
            className="leader-section"
            style={{ backgroundImage: 'url("leaderimg.jpg")' }}
          >
            <div className="container">
              <div className="leader-heading">
                <h2>The Leaders</h2>
              </div>
              <div className="leader-wrapper">
                <div className="row justify-content-start">
                  <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="card leaderbox">
                      <div className="leaderbox-img">
                        <img src={MihirPatel} alt="Mihir Patel"></img>
                      </div>
                      <div className="leaderbox-body">
                        <h5>Mihir Patel </h5>
                        <p>
                          District Magistrate Banaskantha & Chairman
                          <br />
                          Shree Arasuri Ambaji Mata Devasthan Trust , Ambaji
                        </p>
                      </div>
                    </div>
                  </div>
                  <div className="col-xxl-3 col-xl-4 col-lg-4 col-md-4 col-sm-12 col-12">
                    <div className="card leaderbox">
                      <div className="leaderbox-img">
                        <img
                          src={kaushikModi}
                          alt="Kaushik S.Modi (G.A.S)"
                        ></img>
                      </div>
                      <div className="leaderbox-body">
                        <h5>Kaushik S.Modi (G.A.S)</h5>
                        <p>
                          Administrator & Additional Collecter <br />
                          Shri Arasuri Ambaji Mata Devasthan Trust , Ambaji
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* ---------  */}
          <Arasuri />
          <Footer />
        </div>
      ) : (
        <LazyLoader />
      )}
    </>
  );
};

export default AboutUs;
